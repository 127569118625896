import './App.css';
import React, {useState,useMemo,useContext} from 'react';
import ToolBar from "./container/ToolBar";
import {Grid} from '@material-ui/core'
import TourIframe from './components/TourIframe';
import {PanoValueContext} from './context';
function App(props) {
  const [pano_params,setPanoParams] = useState(useContext(PanoValueContext));
  const panoProviderValue = useMemo(()=>{return {pano_params,setPanoParams}},[pano_params,setPanoParams]);
  return (
    <PanoValueContext.Provider value = {panoProviderValue}>
      <Grid container>
        <Grid item xs={12} sm={6} md={6} lg={4} xl = {3} style={{backgroundColor: "#1F1F1F",overflowX:"hidden",overflowY:"auto",maxHeight:"100vh"}}>     
          <ToolBar/>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={8} xl = {9}>
          <TourIframe/>
        </Grid>
      </Grid>
    </PanoValueContext.Provider>
  );
}

export default App
