import React, { useContext, useState, useEffect } from 'react';
import {Grid,Switch,MenuItem,Select,Divider,TextField,InputLabel,Slider,Button, Typography} from '@material-ui/core';
import TourSearchInput from '../TourSearchInput';
import {PanoValueContext} from '../../context';

function PanoParams(props){
    const {pano_params,setPanoParams} = useContext(PanoValueContext)
    const [currentValues,setCurrentValues] = useState({node:pano_params.node,fov:pano_params.fov_target,tilt:pano_params.tilt_target,pan:pano_params.pan_target});
    const [ratio,setRatio] = useState({x:16,y:9});
    useEffect(()=>{
        setPanoParams({...pano_params,aspect_ratio:ratio.x + "/" + ratio.y})
    },[ratio])
    useEffect(()=>{
        window.onmessage = (e)=>{
            if(e.origin.includes(pano_params.basepath) || pano_params.basepath.includes(e.origin)){
                setCurrentValues({
                    node: e.data.values.node.substring(4,e.data.values.node.length),
                    tilt : e.data.values.tilt.toFixed(1),
                    pan: e.data.values.pan.toFixed(1),
                    fov: e.data.values.fov.toFixed(1)
                });
            }
        }
    },[pano_params.basepath])
        return (
            <Grid container direction = "column" spacing = {1}>
                <Grid item xs={12} sm = {12} md={12} lg={12} xl = {12}>
                    <Grid container direction = "row">
                        <TourSearchInput/>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"rgba(255,255,255,0.5)"}}/>
                </Grid>
                <Grid item style={{margin:"8px",alignSelf:"center"}}>
                    <Button variant = "outlined" color = "primary" onClick = {()=>{
                        setPanoParams({...pano_params,node:currentValues.node,fov_target : currentValues.fov,tilt_target: currentValues.tilt, pan_target: currentValues.pan})
                    }}>Set to current Values</Button>
                </Grid>
                <Grid item xs = {4} sm={4} md={3} lg={3} xl = {3} style={{height : "100%"}}>
                    <TextField value= {pano_params.node}
                     type = "number"
                     onChange = {
                         (event)=>{
                             if(event.target.value){
                                setPanoParams({...pano_params,node : event.target.value});
                            }
                        }
                     }
                     style={{width:"100%",marginTop:"10px",marginBottom:"8px"}} id="input_node" label="Node" variant="outlined" />
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"rgba(255,255,255,0.5)"}}/>
                </Grid>
                <Grid item style={{height : "100%",marginTop:"8px"}}>
                    <InputLabel>
                    FOV
                    </InputLabel>
                        <Slider 
                        id="input_fov_target"
                        value= {pano_params.fov_target}
                        step={0.2}
                        max={90}
                        min={0}
                        onChange = {
                            (event,val)=>{
                                    setPanoParams({...pano_params,fov_target : event.target.value || val});
                                }
                            }
                        valueLabelDisplay="on"
                        style={{width:"100%",marginTop:"10px",marginBottom:"8px"}}
                        />
                </Grid>
            
                <Grid item style={{height : "100%"}}>
                    <InputLabel>
                    Tilt
                    </InputLabel>
                        <Slider 
                        id="input_tilt_target"
                        value= {pano_params.tilt_target}
                        step={0.2}
                        max={90}
                        min={-90}
                        onChange = {
                            (event,val)=>{
                                    setPanoParams({...pano_params,tilt_target : event.target.value || val});
                                }
                            }
                        valueLabelDisplay="on"
                        style={{width:"100%",marginTop:"10px",marginBottom:"8px"}}
                        />
                </Grid>
                <Grid item style={{height : "100%"}}>
                    <InputLabel>
                    Pan
                    </InputLabel>
                        <Slider 
                        id="input_pan_target"
                        value= {pano_params.pan_target}
                        step={0.2}
                        max={360}
                        min={-360}
                        onChange = {
                            (event,val)=>{
                                    setPanoParams({...pano_params,pan_target : event.target.value || val});
                                }
                            }
                        valueLabelDisplay="on"
                        style={{width:"100%",marginTop:"10px",marginBottom:"8px"}}
                        />
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"rgba(255,255,255,0.5)"}}/>
                </Grid>
                <Grid item style={{height : "100%",marginTop:"16px"}}>
                    <InputLabel>
                    Fly-In
                    </InputLabel>
                    <Switch
                        checked={pano_params.autoMove}
                        onChange={
                            (event,val)=>{
                                    setPanoParams({...pano_params,autoMove : event.target.value || val});
                                }
                            }
                        name="check_flying"
                    />
                </Grid>

                    {pano_params.autoMove === true
                    ?
                    <Grid item>
                        <Grid container direction = "column" spacing = {1}>
                            <Grid item style={{margin:"8px",alignSelf:"center"}}>
                                <Button variant = "outlined" color = "primary" onClick = {()=>{
                                    setPanoParams({...pano_params,node:currentValues.node,fov_start : currentValues.fov,tilt_start: currentValues.tilt, pan_start: currentValues.pan})
                                }}>Set to current Values</Button>
                            </Grid>
                            <Grid item style={{height : "100%"}}>
                                <InputLabel>
                                FOV Start
                                </InputLabel>
                                <Slider 
                                id="input_fov_start"
                                value= {pano_params.fov_start}
                                step={0.2}
                                max={90}
                                min={0}
                                onChange = {
                                    (event,val)=>{
                                            setPanoParams({...pano_params,fov_start : event.target.value || val});

                                        }
                                    }
                                valueLabelDisplay="on"
                                style={{width:"100%",marginTop:"10px",marginBottom:"8px"}}
                                />
                            </Grid>
                            <Grid item style={{height : "100%"}}>
                                <InputLabel>
                                Tilt Start
                                </InputLabel>
                                    <Slider 
                                    id="input_tilt_start"
                                    value= {pano_params.tilt_start}
                                    step={0.2}
                                    max={90}
                                    min={-90}
                                    onChange = {
                                        (event,val)=>{
                                            setPanoParams({...pano_params,tilt_start : event.target.value || val});
                                            }
                                        }
                                    valueLabelDisplay="on"
                                    style={{width:"100%",marginTop:"10px",marginBottom:"8px"}}
                                    />
                            </Grid>
                            <Grid item style={{height : "100%"}}>
                                <InputLabel>
                                Pan Start
                                </InputLabel>
                                <Slider 
                                id="input_pan_start"
                                value= {pano_params.pan_start}
                                step={0.2}
                                max={360}
                                min={-360}
                                onChange = {
                                    (event,val)=>{
                                        setPanoParams({...pano_params,pan_start : event.target.value || val});
                                        }
                                    }
                                valueLabelDisplay="on"
                                style={{width:"100%",marginTop:"10px",marginBottom:"8px"}}
                                />
                            </Grid>
                            <Grid item style={{height : "100%"}}>
                                <InputLabel>
                                Movement Lock
                                </InputLabel>
                                <Select
                                id="input_moveLock"
                                value= {pano_params.moveLock}
                                onChange= {
                                    (event,val)=>{
                                        setPanoParams({...pano_params,moveLock : event.target.value || val});
                                        }
                                    }
                                displayEmpty
                                >
                                        <MenuItem value={"None"}>None</MenuItem>
                                        <MenuItem value={"All"}>All</MenuItem>
                                        <MenuItem value={"Mousewheel"}>Mousewheel</MenuItem>
                                        <MenuItem value={"Mouse"}>Mouse</MenuItem>
                                        <MenuItem value={"Keyboard"}>Keyboard</MenuItem>
                                        <MenuItem value={"Keyboard_Mousewheel"}>Keyboard+Mousewheel</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing = {2} alignContent="center" justify="center">
                                    <Grid item xs={5}>
                                        <InputLabel>
                                        Movement Delay
                                        </InputLabel>
                                        <TextField 
                                        id="input_moveDelay"
                                        value= {pano_params.moveDelay}
                                        step={100}
                                        type="number"
                                        onChange = {
                                            (event,val)=>{
                                                setPanoParams({...pano_params,moveDelay : event.target.value || val});
                                                }
                                            }
                                        valueLabelDisplay="on"
                                        style={{width:"100%",marginTop:"10px",marginBottom:"8px"}}
                                        variant = "outlined"
                                        />
                                    </Grid>
                                    <Grid item xs = {5}>
                                        <InputLabel>
                                        Movement Speed
                                        </InputLabel>
                                        <TextField 
                                        id="input_moveSpeed"
                                        value= {pano_params.moveSpeed}
                                        step={0.1}
                                        type="number"
                                        onChange = {
                                            (event,val)=>{
                                                    setPanoParams({...pano_params,moveSpeed : event.target.value || val});
                                                }
                                            }
                                        valueLabelDisplay="on"
                                        style={{width:"100%",marginTop:"10px",marginBottom:"8px"}}
                                        variant = "outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>  
                    :
                    <div/>
                    }
                <Grid item>
                    <Divider style={{backgroundColor:"rgba(255,255,255,0.5)"}}/>
                </Grid>
                <Grid item style={{height : "100%",marginTop:"16px"}}>
                    <InputLabel>
                    Hide Hotspots
                    </InputLabel>
                    <Switch
                        checked={pano_params.singleImage}
                        onChange={
                            (event,val)=>{
                                setPanoParams({...pano_params,singleImage : event.target.value || val});
                                }
                            }
                        name="check_singleImage"
                    />
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"rgba(255,255,255,0.5)"}}/>
                </Grid>
                <Grid item>
                            <Grid item>
                                <TextField value= {pano_params.width}
                                    onChange = {
                                        (event)=>{
                                            if(event.target.value){
                                                setPanoParams({...pano_params,width : event.target.value});
                                            }
                                        }
                                    }
                                    style={{width:"100%",marginTop:"10px",marginBottom:"8px"}} 
                                    id="input_width" 
                                    label="Width" 
                                    variant="outlined" 
                                />
                            </Grid>
                            <Grid item>
                                    <Grid container direciton = "row" spacing={2}>
                                        <Grid item style={{alignSelf:"center"}}>
                                            <Typography variant="body1" style={{color:"#FFFFFF50",marginTop:"10px"}}>
                                                Height
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Select
                                            id="input_type"
                                            variant = "outlined"
                                            value= {pano_params.height_type}
                                            style={{marginTop:"10px"}}
                                            onChange = {
                                                (event)=>{
                                                    if(event.target.value){
                                                        setPanoParams({...pano_params,height_type : event.target.value});
                                                    }
                                                }
                                                
                                            }>
                                                <MenuItem value={"aspect-ratio"}>Aspect Ratio</MenuItem>
                                                <MenuItem value={"height"}>CSS Height</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item style={{flex:1}}>
                                            {pano_params.height_type === "height" ? 
                                                <TextField value= { pano_params.height}
                                                    onChange = {
                                                        (event)=>{
                                                            if(event.target.value){
                                                                setPanoParams({...pano_params,height: event.target.value});
                                                            }
                                                        }
                                                    }
                                                    style={{width:"100%",marginBottom:"8px"}} 
                                                    id="input_height" 
                                                    label="Height"
                                                    variant="outlined" 
                                                />
                                            :
                                            <Grid container direction = "row" style={{marginTop:"10px"}}>
                                                    <Grid item style={{flex:1}}>
                                                        <TextField 
                                                        
                                                        type="number" 
                                                        variant="outlined"
                                                        value={ratio.x}
                                                        label="X"
                                                        onChange={(e)=>setRatio(state=>({...state,x:e.target.value}))}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{alignSelf:"center", textAlign:"center",margin:"0 8px"}}>
                                                        <Typography variant="h4" style={{color:"#FFFFFF"}}>:</Typography>
                                                    </Grid>
                                                    <Grid item style={{flex:1}}>
                                                        <TextField 
                                                        type="number" 
                                                        variant="outlined"
                                                        value={ratio.y}
                                                        label="Y"
                                                        onChange={(e)=>setRatio(state=>({...state,y:e.target.value}))}
                                                        />
                                                    </Grid>
                                            </Grid>
                                        }
                                           
                                        </Grid>
                                    </Grid>
                               
                            </Grid>
                            
                </Grid>
                <Grid item xs = {4} sm={4} md={3} lg={3} xl = {3} style={{height : "100%"}}>
                    
                </Grid>
            </Grid>
        );
}

export default PanoParams;