import {createContext} from 'react';
const params = new URL(window.location.href).searchParams;

export const PanoValueContext = createContext({
    basepath :  params.get("basepath") || "https://lechwinter.360ty.cloud/",
    node: params.get("node") || 45,
    fov_target: 70,
    tilt_target: 0,
    pan_target : 0,
    singleImage : false,
    autoMove : false,
    fov_start : 70,
    tilt_start : 0,
    pan_start : 0,
    height_type: "aspect-ratio",
    aspect_ratio: "16:9",
    height: "70vh",
    width: "100%",
    moveDelay : 500,
    moveSpeed : 1,
    moveLock : "Mousewheel"
});