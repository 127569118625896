import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {main} from './themes';
import {ThemeProvider} from '@material-ui/core';


ReactDOM.render(
      <ThemeProvider theme={main}>
        <App />
      </ThemeProvider>,
  document.getElementById('root')
);


