import React, { useContext,useEffect,useState } from 'react';
import OutputCode from '../../container/OutputCode';
import { PanoValueContext } from '../../context';
function TourIframe(props){
    const {pano_params} = useContext(PanoValueContext);
    const [iframeSrc,setIframeSrc] = useState(`${pano_params.basepath}?Pano=node${pano_params.node}&PanoFovTarget${pano_params.fov_target}&PanoTiltTarget=${pano_params.tilt_target}&PanoPanTarget=${pano_params.pan_target}`);
    const [style,setStyle] = useState(pano_params.height_type === "height" ? {border:"none",height:pano_params.height, width:pano_params.width} : {border:"none",aspectRatio:pano_params.aspect_ratio, width:pano_params.width})
    useEffect(()=>{
        var startSrc = `${pano_params.basepath}?Pano=node${pano_params.node}&PanoFovTarget=${pano_params.fov_target}&PanoTiltTarget=${pano_params.tilt_target}&PanoPanTarget=${pano_params.pan_target}`;
        var autoMovePart = `&PanoFovStart=${pano_params.fov_start}&PanoTiltStart=${pano_params.tilt_start}&PanoPanStart=${pano_params.pan_start}&AutoMove&MoveLock=${pano_params.moveLock}&MoveSpeed=${pano_params.moveSpeed}&MoveDelay=${pano_params.moveDelay}`;
        var singleImagePart = `&SingleImage`;
        if(pano_params.autoMove){
            startSrc = startSrc+autoMovePart;
        }
        if(pano_params.singleImage){
            startSrc = startSrc+singleImagePart;
        } 
        setIframeSrc(startSrc)
        setStyle(pano_params.height_type === "height" ? {border:"none",height:pano_params.height, width:pano_params.width} : {border:"none",aspectRatio:pano_params.aspect_ratio, width:pano_params.width})
    },[pano_params])
   
    return (
        <div style={{height:"100vh",width:"100%"}}>
            <iframe src ={iframeSrc} style={style}>

            </iframe>
            <OutputCode iframeSrc = {iframeSrc}/>
        </div>
        );
}

export default TourIframe;