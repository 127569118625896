import React, { useState, useContext } from 'react';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import {outputTheme} from '../../themes';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ThemeProvider} from '@material-ui/core';
import {PanoValueContext} from '../../context';

function OutputCode(props) {
    const [copied, setCopied] = useState(false);
    const {pano_params} = useContext(PanoValueContext);

    var iframeString = 
    `<iframe src="${props.iframeSrc}"
    style="border:none;width:${pano_params.width};${pano_params.height_type === "height" ? `height:${pano_params.height}`: `aspect-ratio:${pano_params.aspect_ratio}`};">
</iframe>`;
        return (
            <div style={{overflow:"hidden"}}>
            <ThemeProvider theme={outputTheme}>
                <CopyToClipboard 
                text={iframeString}
                onCopy={()=>{setCopied(true); setTimeout(()=>{setCopied(false)},1500)}}
                >
                {
                    copied === false?
                    <FileCopyOutlinedIcon
                    style={{position:"absolute",zIndex:2,right:0,marginTop:".5em",cursor:"pointer",color:"white"}}
                        />
                        :
                        <div style={{position:"absolute",zIndex:2,right:0,marginTop:".5em",cursor:"pointer"}}>
                        <CheckCircleOutlinedIcon
                        style={{color:"green",float:"right"}}
                        />
                    </div>
                    }
                </CopyToClipboard>
                <SyntaxHighlighter 
                language="htmlbars"
                style={a11yDark}
                >
{iframeString}
                </SyntaxHighlighter>
            </ThemeProvider>
          </div>
        );
}

export default OutputCode;